.image {
  min-height: 600px;
  max-height: 600px;
}

.banner {
  background-color: #0d3c40;
}


@media (max-width: 480px) { 
  .image {
    flex-direction: row;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: table-cell;
    width: 100%;
  }
  .banner {
    background-color: #0d3c40;
  }
  
}

@media (max-width: 767px) {
  .image {
    flex-direction: row;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 15px;
    padding-bottom: 15px;
    max-height: 300px;
    min-width: 950px;
  }
  .banner {
    background-color: #0d3c40;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .image {
    flex-direction: row;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: table-cell;
    max-height: 300px;
    min-width: 950px;
  }
  .banner {
    background-color: #0d3c40;
  }

}


