.public {
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 15px;
  min-height: 300px;
}

.titulos{
  background-color: #00738A !important;
}

.botoes {
  background-color: #006072 !important;
 color: white !important;
}

.botoes:hover {
  background-color: #00738A !important;
 color: white !important;
}
