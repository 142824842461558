.footer {
 width: 80%;
 margin: 20px auto;
}

.contato{
  font-size: 15pt !important;
  text-align: justify;
}
.Img-Footer{
  width: 100%;
}
.Img-Footer img{
  width: 100%;
  padding: 10px;
}

@media screen and (max-width: 720px){
.Img-Footer img{
  width: 100%;
}
.contato{
  font-size: 15pt !important;
  text-align: center;
}
}