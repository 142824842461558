.img-sidebar{
  width: 50% !important;
}
.grupo{
  width: 80%;
  margin: 0 auto;
  height: 120px;
  display: flex;
  align-items: center;
  
}
.content{
  background-image: linear-gradient(to right, #241F56, #241F56);
  min-height: 120px;
  width: 100%;
 }
.text-dashboard{
  color: #C3BBEF;
  font-family: 'Quicksand', sans-serif;
  font-size: 0.85em !important;
  padding-left: 40px;
}