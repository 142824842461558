.footer {
 text-align: center;
}

.contato{
  font-size: 12pt;
  margin-top: 20px;
}

.logo{
  width: 80%;
  
}