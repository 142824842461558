.container-slide-index{
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}

.container-slide-index button{
float: center;
width: 20%;
height: 40px;
border-radius: 10px;
}

.titulo{
  color: black !important;
  font-size: 1.5em;
  font-family: 'Quicksand', sans-serif;
  float: left;
}

.content-slide-index{
  width: 100%;
  display: flex;
  text-align: center;  
}
.input-slide-index{
  width: 100%;
  padding-bottom: 30px;
}

.textarea-slide-index{
  width: 50%;
  height: 100%;
}

.inputFile{
  padding: 40px;
}

.upbutton{
  color: white;
  text-align: center;
  background-color: #241F56;
}

.Cadastro-Produto-Cultural textarea{
width: 90% !important;
margin: 0 auto ;
}