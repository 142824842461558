.embed{
  margin-bottom: 20px;
}
.container-teste{
  max-width: 1280px;
  margin: auto;
}
.fundoLogin{
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
}

.text_login{
  color: white;
  width: 100px;
  height: 15px;
  padding-left: 25px;
  margin-top: -15px;
  cursor: pointer;
  padding-right: 45px;
}

.input-login{
  width: 250px;
  height: 50px;
border-radius: 5px;
margin-top: 10px;
}

.campo-login{
  width: 400px;
  height: 500px;
  background-color: #e0ecf5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-top: 150px;
  position: relative;
}

.img-login{
  width: 80% !important;
  margin-bottom: 50px;
}

.News{
    text-align: -webkit-center;
    background-color: #EBE5FA;
    margin: 30px 0;
}
.News img {
  max-width: 80%;
  min-height: 450px
}
.news-text-container{
  margin: 0 60px 0 0; 
  justify-content: space-between
   
}
.news-title{
  font-size: 2rem;
}
.news-resumo{
  font-size: 1rem;
  text-align: justify;
}
.news-text-container button{
  float: right;
}

.footer{
  display: flex;
  flex-direction: row !important;
  justify-content: center;
}
.iframe-live{
  margin: 50px 0;
}
@media screen and (max-width: 720px){
.news-container{
   flex-direction: column !important;
}
.News img {
  max-width: 100%;
  min-height: 350px
}
.news-text-container{
width: 100%;
margin: 10px 0 !important;
}
.news-resumo{
  width: 90%;
}
.img-container{
  float: left;
}
.sidebar-mobile{
  color: white;
  z-index: 999;
}
.subMenu-dashboard{
 display: none;
}
.conteiner-index{
justify-content: space-between;
width: 70% !important;
}
.text-dashboard{
  color: black !important;
  padding: 10px;
}
.menu-mb{
  margin: 20px 0;
  width: 200px
}
.footer{
  display: flex;
  flex-direction: column !important;
}
.embed{
  margin: 20px 0;
}
.embed embed{
  display: none;
}
.embed a {
  margin: 20px 0 !important;
  display: inline !important;
}
.Informes{
  width: 100% !important;
  display: flex;
  flex-direction: column;
}
.Informes body{
  float: left !important;
}
.Informes a {
  margin-left: -30px;
}
}


button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
button.learn-more {
  height: auto;
}
button.learn-more .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
}
button.learn-more .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.learn-more .circle .icon.arrow {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
button.learn-more .button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}
button:hover .circle {
  width: 100%;
}
button:hover .circle .icon.arrow {
  background: #fff;
  -webkit-transform: translate(1rem, 0);
          transform: translate(1rem, 0);
}
button:hover .button-text {
  color: #fff;
}
