.form {  
  padding-top: 15px;
  padding-bottom: 15px;
}

.campo_form{
  width: 31%;
  margin-right: 15px !important;
}

 .btn_upload{
  background-color: #00738A !important;
  color: white !important;
 }

 .list{
   display: flex;
   background-color: rgb(239, 239, 239);
 }

/* max-width  */
@media screen and (max-width: 600px) {
  .form {  
width: 100%;
  }

  .file {
    width: 100% !important;
  }
 
}

/* min-width */
@media screen and (min-width: 900px) {

  
   .btn_upload{
    background-color: #00738A !important;
    color: white !important;
   }
  
   .dropzone{
     width: 20% ;
     height: 10%;
   }
  
   .list{
     display: flex;
     background-color: rgb(239, 239, 239);
   }

}

/* min-width & max-width */
@media screen and (min-width: 600px) and (max-width: 900px) {
  .campo_form{
    width: 100%;
  }
  
   .btn_upload{
    background-color: #00738A !important;
    color: white !important;
   }
  
   .dropzone{
     width: 20% ;
     height: 10%;
   }
  
   .list{
     display: flex;
     background-color: rgb(239, 239, 239);
   }

   .file {
    width: 100% !important;
  }
}

/* max device width */
@media screen and (max-device-width: 480px) {
      .campo_form{
        width: 100%;
      }
      
       .btn_upload{
        background-color: #00738A !important;
        color: white !important;
       }
      
       .dropzone{
         width: 20% ;
         height: 10%;
       }
      
       .list{
         display: flex;
         background-color: rgb(239, 239, 239);
       }

       .file {
        width: 100% !important;
      }
    
}