:root {
  --cor-tema: #006499;
  --cor-tema-claro: #1d8dc9;
  --cor-primaria: rgb(10,99,167);
  --cor-secundaria: rgb(17,131,17);
  --cor-terciaria: rgb(176,22,22);
  --cor-amarelo: rgb(220,204,26);

  --cor-preta: #000;
  --cor-branca: #FFF;
  --cor-cinza-claro: #EEE;
  --cor-cinza-escuro: #222;
  --cor-cinza-medio: rgba(68, 68, 68, 0.747);
  
}

.full-width{width: 100%;}
.full-height{height: 100vh;}

/* FLEX */
.flex{  display: flex;}
.horizontal{flex-direction: row;}
.vertical{flex-direction: column;}
.space-between{justify-content: space-between;}

.flex-1{flex: 1;}
.flex-2{flex: 2;}
.flex-3{flex: 3;}
.flex-4{flex: 4;}
.flex-5{flex: 5;}
.flex-6{flex: 6;}
.flex-7{flex: 7;}
.flex-8{flex: 8;}

.flex-start{
  justify-content: flex-start;
  align-items: center;
}
.flex-end{
  justify-content: flex-end;
  align-items: center;
}
.flex-center{
  justify-content: center;
  align-items: center;
}

.grid-1{
  width: 100%;
}
.grid-2{
  width: 45%;
}
.grid-3{
  width: 30%
}

/* BUTTONS */

.button{
   font-size: 1rem;
   padding: 10px 15px;
   margin: 5px;
   border: 1px solid #aaaa;
   cursor: pointer;
   border-radius: 10px;
   font-weight: bold;
 }
.button-small{
  font-size: 0.8rem;
  padding: 5px 8px;
  margin: 3px;
}
 .button-modal{
  background-color: var(--cor-tema-claro);
  color: var(--cor-branca);
  border: 0;
  width: 200px;
}
 .button-info{
  background-color: var(--cor-tema-claro);
  color: var(--cor-branca);
  border: 0;
}

.button-success{
  background-color: var(--cor-secundaria);
  color: var(--cor-branca);
  border: 0;
}
 .button-danger{
   background-color: var(--cor-terciaria);
   color: var(--cor-branca);
   border: 0;
 }

.Container-Texto-Index{
  width: 80%;
  margin: 20px auto 50px auto;
  font-family: 'Quicksand', sans-serif;
  text-align: justify;
}
.Container-Texto-Index img {
  max-width: 265px;
  width: 40vw !important
}
.Container-Texto-Index ul{
 padding: 0;
}
.Informes{
width: 80%;
margin: 0 auto;
}
.Informes body{
  font-size:100%;
  margin:1em;
  background:#666;
  color:#fff;  
  margin: 0 auto;
}
.Informes h2,p{
  font-size:100%;
  font-family: 'Quicksand', sans-serif;
  font-weight:normal;
  
}
.Informes ul,li{
  list-style:none;
  
}
.Informes ul{
  overflow:hidden;
  padding:3em;
  width: 100%;
}
.Informes ul li a{
  text-decoration:none;
  color:#000;
  background:#ffc;
  display:block;
  height:20em;
  width:20em;
  padding:1em;
  -moz-box-shadow:5px 5px 7px rgba(33,33,33,1);
  -webkit-box-shadow: 5px 5px 7px rgba(33,33,33,.7);
  box-shadow: 5px 5px 7px rgba(33,33,33,.7);
  -moz-transition:-moz-transform .15s linear;
  -o-transition:-o-transform .15s linear;
  -webkit-transition:-webkit-transform .15s linear;
}
.Informes ul li{
  margin:1em;
  float:left;  
}
.Informes ul li h2{
  font-size:140%;
  font-weight:bold;
  padding-bottom:10px;
}
.Informes ul li p{
 font-family: 'Quicksand', sans-serif;
  font-size:0.85rem;
}
.Informes ul li a{
  -webkit-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  -moz-transform:rotate(-6deg);
}
.Informes ul li:nth-child(even) a{
  -o-transform:rotate(4deg);
  -webkit-transform:rotate(4deg);
  -moz-transform:rotate(4deg);
  position:relative;
  top:5px;
  background:#cfc;
}
.Informes ul li:nth-child(3n) a{
  -o-transform:rotate(-3deg);
  -webkit-transform:rotate(-3deg);
  -moz-transform:rotate(-3deg);
  position:relative;
  top:-5px;
  background:#ccf;
}
.Informes ul li:nth-child(5n) a{
  -o-transform:rotate(5deg);
  -webkit-transform:rotate(5deg);
  -moz-transform:rotate(5deg);
  position:relative;
  top:-10px;
}
.Informes ul li a:hover,ul li a:focus{
  box-shadow:10px 10px 7px rgba(0,0,0,.7);
  -moz-box-shadow:10px 10px 7px rgba(0,0,0,.7);
  -webkit-box-shadow: 10px 10px 7px rgba(0,0,0,.7);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -o-transform: scale(1.25);
  position:relative;
  z-index:5;
}
.Informes ol{text-align:center;}
.Informes ol li{display:inline;padding-right:1em;}
.Informes ol li a{color:#fff;}

.container-iframes{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
}
.container-iframe{
display: flex;
}
.container-iframe button{
  height: 20%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.Detalhes-Iframe{
  font-size: 2.25em;
  line-height: 20px;
  background-color: white;
  width: 300px;
	color: rgb(0, 0, 0);
	font-style: italic;
	font-weight: normal;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-left: 5%;
  border-radius: 10px;
   box-shadow:2px 2px 7px rgba(33,33,33,1);
  opacity: 0;
  transition: 1s;
}
.Detalhes-Iframe p {
  font-size: 1.2rem;
}
.Menu-Collapse button{
background-color: #004680;
width: 100%;
text-align: start;
font-size: 1.3rem;
margin-bottom: 20px;
height: 50px;
box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.322);
}
.Produto-Cultural{
  width: 90%;
  margin: 0 auto;
}
.Produto-Cultural button{
 margin-bottom: 10px;
  background-image: linear-gradient(to right, #004680, #004680);
}
.Container-Produto-Cultural1{
  background-image: linear-gradient(to right, #241F56, #241F56);
  margin-bottom: 5px;
  color: white;
   padding: 20px;
}
.Container-Produto-Cultural2{
  background-image: linear-gradient(to right, #5f58a5, #5f58a5);
  margin-bottom: 5px;
  color: white;
   padding: 20px;
}
.Container-SubMenu{
  width: 98%;  
  float: right;
  z-index: -1;
  display: flex; 
}
.SubMenu1{
  margin-top: -20px;
  margin-left: -15px;
  background-color: #a192f8;
  padding: 20px;
}
.SubMenu2{
  border-top-left-radius: 15px;
  margin-top: -20px;
  margin-left: -15px;
  background-color: #7660f3;
   padding: 20px;
}
.container-iframe-submenu{
  display: flex;
  flex-wrap: wrap;  
}
.iframe-submenu{
  display: flex;
  width: 100%;
  margin-top: 15px;
}
.iframe-submenu button{
  height: 40px;
}
.container-iframes-submenu{
  width: 90%;
  text-align: -webkit-center;
}

.Candidato{
  border: 1px solid #000;
  margin: 0 20% 0 20%;
  padding: 10px;
  font-size: 1.1rem;
}
.Modal{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Card-Modal{
  background-color: rgb(231, 231, 231);
  width: 600px;
    border: '2px solid #000';
    box-shadow:inset 0 0 1em rgba(112, 112, 112, 0.438), rgba(63, 62, 62, 0.452);
    padding: 40px
}
.Card-Modal h2{
  text-align: center;
  margin-bottom: 20px;
}
.Input-Simples .inputcep{
  width: -webkit-fill-available;
  padding: 5px;
  font-size: 2.2rem;
  text-align: center;
}
.Input-Simples-Modal .inputcep{
  width: -webkit-fill-available;
  padding: 5px;
  font-size: 1.2rem;
}

.Observacoes {
  padding: 50px 0 20px 0;
}
.Links-Download{
  width: 80%;
 margin: 0 0 0 10%;
}
.Apresentacao{
  margin: 10px 0 10px 0;
  font-size: 1.2rem;
}
.Landing-Page{
  margin: 5% 10% 0 10%;
}
.Calendario-Landing-Page{
  margin: 0 auto;
}
.Calendario-Landing-Page td{
  padding: 10px;
}
.Calendario-Landing-Page tr:nth-child(even){
background:rgb(173, 165, 218);
}
.Calendario-Landing-Page tr:nth-child(odd){
background: rgb(212, 208, 235)
}
.Titulo-Landing-Page{
  margin: 5% 10% 0 10%;
  color: #241F56;
   font-family: 'Quicksand', sans-serif;
}
.fmc20{
  width: 80%;
  margin: 0 auto;
  text-align: justify;
}
.contato{
  justify-content: start !important;
}

.MuiTypography-h5{
  font-size: 0.85rem !important;
  font-weight: 700 !important;
}
/*MOBILE*/

@media screen and (max-width: 720px){
  .Card-Modal{
  width: 100%;
  overflow: scroll;
  height: -webkit-fill-available;
}
.button-modal{
  width: 100px;
}
.fmc20{
  width:100% !important;
  text-align: justify;
}
.Links-Download{
  width: 100%;
 margin: 0 0 0 5% !important;
}
.Candidato{
  margin: 0 5% 5% 5%;
}
}
