:root {
  --cor-tema: #006499;
  --cor-tema-claro: #1d8dc9;
  --cor-primaria: rgb(10,99,167);
  --cor-secundaria: rgb(17,131,17);
  --cor-terciaria: rgb(176,22,22);
  --cor-amarelo: rgb(220,204,26);

  --cor-preta: #000;
  --cor-branca: #FFF;
  --cor-cinza-claro: #EEE;
  --cor-cinza-escuro: #222;
  --cor-cinza-medio: rgba(68, 68, 68, 0.747);
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--cor-branca);
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

.full-width{width: 100%;}
.full-height{height: 100vh;}

/* FLEX */
.flex{  display: flex;}
.horizontal{flex-direction: row;}
.vertical{flex-direction: column;}
.space-between{justify-content: space-between;}

.flex-1{flex: 1;}
.flex-2{flex: 2;}
.flex-3{flex: 3;}
.flex-4{flex: 4;}
.flex-5{flex: 5;}
.flex-6{flex: 6;}
.flex-7{flex: 7;}
.flex-8{flex: 8;}

.flex-start{
  justify-content: flex-start;
  align-items: center;
}
.flex-end{
  justify-content: flex-end;
  align-items: center;
}
.flex-center{
  justify-content: center;
  align-items: center;
}

.grid-1{
  width: 100%;
}
.grid-2{
  width: 45%;
}
.grid-3{
  width: 30%
}

/* COMPONENTES */

.Login{
  align-items: center;
  margin-top: 50px;
}
.Login button{
  background-color: green;
  color: white;
  width: 100px;
}
.button{
  width: 100%;
}
.Button-Login{
  align-items: center;
}
.Login .Card{
  width: 95%;
  max-width: 450px;
}
.Login h1{
  font-size: 3rem;
  color: rgb(20,3,61);
  margin-bottom: 0;
}
.Recuperar-Senha h1{
  font-size: 3rem;
  color: rgb(20,3,61);
  margin-bottom: 0;
}
.Resetar-Senha h1{
  font-size: 3rem;
  color: rgb(20,3,61);
  margin-bottom: 0;
}
.Login a {
  text-decoration: none;
  color: var(--cor-cinza-medio);
}