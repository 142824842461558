.mostra-navbar {
  background: #1C1C1C !important;
  color: white !important;
  padding-left: 240px;
  padding-right: 160px;
  border-top: yellow solid 2px;
}

.mostra-navbar .btn-menu {
  color: white;
  padding: 20px;
}

.mostra-navbar .rede-social {
  float: right;
  padding-top: 20px;
}

.navbar-link1 {
  text-decoration: none;
  padding: 0;
  height: 115px;
  width: 115px;
  font-size: 25px !important;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  transition: .5s ease;
  border: solid 4px orange;
  overflow: hidden;
  position: relative;  
}
.navbar-link1 img {
  text-decoration: none;
    height: 107px;
    width: 107px;
    background-repeat: no-repeat;
    border-radius: 50%;
    transition: transform 0.7s, filter 1.5s ease-in-out;
    filter: brightness(70%) sepia(100%);
}

.navbar-link1 img:hover {
  text-decoration: none;
  color: #fff !important;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  filter: brightness(110%);
  transform: scale(1.1);
}

.navbar-link2 {
  text-decoration: none;
  padding: 0;
  height: 115px;
  width: 115px;
  font-size: 25px !important;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  transition: .5s ease;
  border: solid 4px orange;
  overflow: hidden;
  position: relative;  
}

.navbar-link2 img {
  text-decoration: none;
    height: 107px;
    width: 107px;
    background-repeat: no-repeat;
    border-radius: 50%;
    transition: transform 0.7s, filter 1.5s ease-in-out;
    transform-origin: center center;
    filter: brightness(70%) sepia(100%);
}

.navbar-link2 img:hover {
  text-decoration: none;
  color: #fff !important;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  filter: brightness(110%);
  transform: scale(1.1);
}

.navbar-link3 {
  text-decoration: none;
  padding: 0;
  height: 115px;
  width: 115px;
  font-size: 25px !important;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  transition: .5s ease;
  border: solid 4px orange;
  overflow: hidden;
  position: relative;  
}
.navbar-link3 img {
  text-decoration: none;
    height: 107px;
    width: 107px;
    background-repeat: no-repeat;
    border-radius: 50%;
    transition: transform 0.7s, filter 1.5s ease-in-out;
    transform-origin: center center;
    filter: brightness(70%) sepia(100%);
}

.navbar-link3 img:hover {
  text-decoration: none;
  color: #fff !important;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  filter: brightness(110%);
  transform: scale(1.1);
}

.navbar-link4 {
  text-decoration: none;
  padding: 0;
  height: 115px;
  width: 115px;
  font-size: 25px !important;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  transition: .5s ease;
  border: solid 4px orange;
  overflow: hidden;
  position: relative;
}
.navbar-link4 img {
  text-decoration: none;
    height: 107px;
    width: 107px;
    background-repeat: no-repeat;
    border-radius: 50%;
    transition: transform 0.7s, filter 1.5s ease-in-out;
    transform-origin: center center;
    filter: brightness(70%) sepia(100%);
}

.navbar-link4 img:hover {
  text-decoration: none;
  color: #fff !important;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  filter: brightness(110%);
  transform: scale(1.1);
}

.navbar-link5 {
  text-decoration: none;
  padding: 0;
  height: 115px;
  width: 115px;
  font-size: 25px !important;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  transition: .5s ease;
  border: solid 4px orange;
  overflow: hidden;
  position: relative;
  
}
.navbar-link5 img {
  text-decoration: none;
    height: 107px;
    width: 107px;
    background-repeat: no-repeat;
    border-radius: 50%;
    transition: transform 0.7s, filter 1.5s ease-in-out;
    transform-origin: center center;
    filter: brightness(70%) sepia(100%);
}

.navbar-link5 img:hover {
  text-decoration: none;
  color: #fff !important;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  filter: brightness(110%);
  transform: scale(1.1);
}

.titulo {
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: white;
}

/* Telefones em paisagem e abaixo */
@media (max-width: 480px) {
  .navbar {
    background: rgb(1,102,121);
    background: linear-gradient(90deg, rgba(1,102,121,1) 0%, rgba(0,77,92,1) 45%, rgba(0,98,117,1) 100%);
    flex-flow: nowrap;
    text-align: center;
  }

}

/* Telefones em paisagem a tablet em retrato */
@media (max-width: 767px) {
  .navbar {
    background: rgb(1,102,121);
    background: linear-gradient(90deg, rgba(1,102,121,1) 0%, rgba(0,77,92,1) 45%, rgba(0,98,117,1) 100%);
    flex-flow: nowrap;
    text-align: center;
  }
}

/* Tablet em retrato a paisagem e desktop */
@media (min-width: 768px) and (max-width: 979px) {
  .navbar {
    background: rgb(1,102,121);
    background: linear-gradient(90deg, rgba(1,102,121,1) 0%, rgba(0,77,92,1) 45%, rgba(0,98,117,1) 100%);
    flex-flow: nowrap;
    text-align: center;
  }
}

/* Desktop grande */
@media (min-width: 1200px) {
 /* estilos aqui */
}