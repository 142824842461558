.mostra-header {
  background-color: #00738A;
  color: white;
  flex-direction: column;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.mostra-banner-img{
  width: 100% !important;
  height: auto !important;
  padding: 0 !important;
}

.mostra-header-text {
  text-align: justify;
  font: inherit;
  font-style: inherit;
    font-variant-ligatures: inherit;
    font-variant-caps: inherit;
    font-variant-numeric: inherit;
    font-variant-east-asian: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    font-size: 100%;
    line-height: inherit;
    font-family: inherit;
}

.mostra-header-text2 {
  color: #D48A00;
  font-size: 12pt;
  font-weight: bold;
  padding-top: 16px;
  padding-bottom: 16px;  
}

.mostra-banner-img {
  background-color: #00738A;
  flex-direction: row;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 15px;
  padding-bottom: 15px;
}












.container {
  position: relative;
  width: 50%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008CBA;
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

@media (max-width: 480px) { 
  .banner {
    background-color: #00738A;
    flex-direction: row;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: table-cell;
  }
  
}

@media (max-width: 767px) {
  .banner {
    background-color: #00738A;
    flex-direction: row;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: table-cell;
  }
  .header-img{
    width: 100%;
  }
  .header-img2{
    align-self: center;

  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .banner {
    background-color: #00738A;
    flex-direction: row;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: table-cell;
  }
  .header-img{
    width: 100%;
  }
  .header-img2{
    align-self: center;

  }
}

