.header_index {
  background-image: linear-gradient(to right, #004680, #004680);
  min-height: 120px;
  text-align: -webkit-center;
}
.menu-dashboard{
  width: 40% !important;
}
.container{
  width: 100%;
}

.conteiner-index .img-sidebar{
  width: 60% !important;
}
.conteiner-index{
  width: 80%;
  margin: 0 auto;
  height: 120px;
  display: flex;
  align-items: center;
}
.subMenu-dashboard{
  display: flex;
  width: 100% !important;
  flex-direction: row;
  float: right;
  align-items: center;
  font-size: 1.4rem;
  justify-content: flex-end;
}
.sidebar-mobile{
  display: none !important;
}
.txt-submenu{
  width: 16%;
}
.text-dashboard{
  color: #C3BBEF;
  font-family: 'Quicksand', sans-serif;
}
.text-dashboard:hover{
  color: white;
}

@media screen and (max-width: 720px){
 .conteiner-index .img-sidebar{
  width: 100px !important;
}
.subMenu-dashboard{
  font-size: 0.9rem;
}
.sidebar-mobile{
  display: block !important;
}
}
